// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-beta-index-tsx": () => import("./../../src/pages/beta/index.tsx" /* webpackChunkName: "component---src-pages-beta-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-status-index-tsx": () => import("./../../src/pages/status/index.tsx" /* webpackChunkName: "component---src-pages-status-index-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../src/templates/blog.template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

